<template>
    <v-carousel
        v-model="model"
        class="hero-carousel"
        :height="cssHeightValue"
        :cycle="!paused"
        :interval="interval"
        hide-delimiter-background
    >

        <template #prev="{ props }">
            <SliderButton
                type="prev"
                alt="Previous Slide"
                v-bind="props"
                @mouseenter="togglePause(true)"
                @mouseleave="togglePause(false)"
            ></SliderButton>
        </template>
        <template #next="{ props }">
          <SliderButton
                type="next"
                alt="Next Slide"
                v-bind="props"
                @mouseenter="togglePause(true)"
                @mouseleave="togglePause(false)"
            ></SliderButton>
        </template>

        <!-- Mark 3V -->
        <v-carousel-item>
            <NuxtLink
                class="d-block video-panel video-panel-mark-3v position-relative fill-width fill-height"
                to="/mens-lacrosse/heads/mark-3v?sku=mark-3v-strung-mid-type-5s-raw-white"
            >
                <video
                    class="object-fit-cover fill-width fill-height"
                    playsinline
                    autoplay
                    muted
                    loop
                >
                    <source
                        :src="`${cdnBasePathMain}/static/common/videos/hero/mark-3v-1.mp4`"
                        type="video/mp4"
                    >
                    <track kind="captions">
                </video>
                <div
                    class="video-panel-content position-absolute pa-6 rounded-lg text-center d-flex flex-column align-center justify-center"
                >
                    <div
                        style="margin-bottom: 4vh"
                    >
                        <v-img
                            class="img-logo"
                            :src="`${cdnBasePathMain}/static/common/images/carousel-home/mark-3v-logo.svg`"
                            :width="isMobile ? '60vw' : '32vw'"
                            height="auto"
                            alt="Tech Trad"
                        ></v-img>
                    </div>
                    <!-- <h4
                        class="font-weight-bold text-uppercase px-2 no-invert text-outline"
                        :class="isMobile ? 'text-h6 my-4' : 'text-h4 my-6'"
                    >
                        TAGLINE HERE...
                    </h4> -->

                    <div
                        class="secondary ma-2 bg-secondary btn-universal btn-universal-sim v-btn v-theme--light v-btn--density-default rounded-md v-btn--size-default v-btn--variant-flat"
                        :class="{ 'v-btn--size-large': !isMobile }"
                    >
                        Preorder Now
                    </div>
                </div>
            </NuxtLink>
        </v-carousel-item>

        <!-- Type 5 Mesh -->
        <v-carousel-item>
            <NuxtLink
                class="d-block video-panel video-panel-type-5 position-relative fill-width fill-height"
                to="/mens-lacrosse/mesh/mens-type-5?sku=type-5-type-5x-custom-string-kit-white"
            >
                <video
                    class="object-fit-cover fill-width fill-height"
                    playsinline
                    autoplay
                    muted
                    loop
                >
                    <source
                        :src="`${cdnBasePathMain}/static/common/videos/hero/MeshBannerVideo-1600x640.mp4`"
                        type="video/mp4"
                    >
                    <track kind="captions">
                </video>
                <div
                    class="video-panel-content position-absolute pa-6 rounded-lg text-center"
                >
                    <h4
                        class="text-md-h1 text-h2 font-weight-bold text-uppercase px-2 mb-6 no-invert text-outline"
                    >
                        TYPE 5 MESH
                    </h4>
                    <h5
                        class="text-md-h4 text-h5 font-weight-bold px-2 mb-6 no-invert text-outline"
                    >
                        CONSISTENCY IS KING
                    </h5>
                    <div
                        class="secondary ma-2 bg-secondary btn-universal btn-universal-sim v-btn v-theme--light v-btn--density-default rounded-md v-btn--size-default v-btn--variant-flat"
                        :class="{ 'v-btn--size-large': !isMobile }"
                    >
                        Shop Now
                    </div>
                </div>
            </NuxtLink>
        </v-carousel-item>

        <!-- Tech Trad -->
        <v-carousel-item>
            <NuxtLink
                class="d-block video-panel video-panel-tech-trad position-relative fill-width fill-height"
                to="/search/womens-lacrosse/sticks/?category=cat_VnFv7otul96on&ordering=sort_order&f=attval_20BVvw2zhglDU"
            >
                <video
                    class="object-fit-cover fill-width fill-height"
                    playsinline
                    autoplay
                    muted
                    loop
                >
                    <source
                        :src="`${cdnBasePathMain}/static/common/videos/hero/tech-trad-1/video.mp4`"
                        type="video/mp4"
                    >
                    <track kind="captions">
                </video>
                <div
                    class="video-panel-content position-absolute pa-6 rounded-lg text-center d-flex flex-column align-center justify-center"
                >
                    <div>
                        <v-img
                            class="img-logo"
                            :src="`${cdnBasePathMain}/static/common/images/carousel-home/tech-trad-1/logo.svg`"
                            :width="isMobile ? '70vw' : '36vw'"
                            height="auto"
                            alt="Tech Trad"
                        ></v-img>
                    </div>
                    <h4
                        class="font-weight-bold text-uppercase px-2 no-invert text-outline"
                        :class="isMobile ? 'text-h6 my-4' : 'text-h4 my-6'"
                    >
                        More Power. More Control. More Consistency.
                    </h4>

                    <div
                        class="secondary ma-2 bg-secondary btn-universal btn-universal-sim v-btn v-theme--light v-btn--density-default rounded-md v-btn--size-default v-btn--variant-flat"
                        :class="{ 'v-btn--size-large': !isMobile }"
                    >
                        Shop Now
                    </div>
                </div>
            </NuxtLink>
        </v-carousel-item>

        <!-- Baseball -->
        <v-carousel-item>
            <div
                class="d-block video-panel video-panel-bbcor position-relative fill-width fill-height"
            >
                <video
                    class="object-fit-cover fill-width fill-height"
                    playsinline
                    muted
                    loop
                    autoplay
                >
                    <source
                        :src="`${cdnBasePathMain}/static/common/videos/hero/baseball-3.mp4`"
                        type="video/mp4"
                    >
                    <track kind="captions">
                </video>
                <div
                    class="video-panel-content position-absolute pa-6 rounded-lg text-center d-flex flex-column align-center justify-center"
                >
                    <div
                        class="mb-8"
                    >
                        <v-img
                            class="img-logo"
                            :src="`${cdnBasePathMain}/static/common/images/carousel-home/stringking-baseball-header-logo.svg`"
                            :width="isMobile ? '70vw' : '30rem'"
                            height="auto"
                            alt="StringKing Baseball"
                        ></v-img>
                    </div>
                    <h5
                        class="text-md-h4 text-h5 font-weight-bold px-2 mb-6 no-invert text-outline"
                    >
                        MORE POWER. MORE HITS.
                    </h5>
                    <div
                        class="d-flex flex-wrap gap-6 justify-center align-center"
                    >
                        <v-btn
                            color="secondary"
                            :size="isMobile ? 'default' : 'large'"
                            to="/search/baseball/bbcor-baseball-bats"
                        >
                             BBCOR
                        </v-btn>
                        <v-btn
                            color="secondary"
                            :size="isMobile ? 'default' : 'large'"
                            to="/search/baseball/usssa-baseball-bats"
                        >
                            USSSA
                        </v-btn>
                        <v-btn
                            color="secondary"
                            :size="isMobile ? 'default' : 'large'"
                            to="/search/baseball/usabat-baseball-bats"
                        >
                            USA
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-carousel-item>

        <!-- Metal 3 Pro -->
        <v-carousel-item>
            <NuxtLink
                class="d-block video-panel video-panel-metal-3-pro position-relative fill-width fill-height"
                to="/mens-lacrosse/shafts/mens-metal-3-pro-attack?sku=mens-metal-3-pro-attack-175-grams-silver"
            >
                <video
                    class="object-fit-cover fill-width fill-height"
                    playsinline
                    autoplay
                    muted
                    loop
                >
                    <source
                        :src="`${cdnBasePathMain}/static/common/videos/hero/shaft-V2.mp4`"
                        type="video/mp4"
                    >
                    <track kind="captions">
                </video>
                <div
                    class="video-panel-content position-absolute pa-6 rounded-lg text-center"
                >
                    <h4
                        class="text-md-h1 text-h2 font-weight-bold text-uppercase px-2 mb-6 no-invert text-outline"
                    >
                        METAL 3 PRO SHAFTS
                    </h4>
                    <div
                        class="secondary ma-2 bg-secondary btn-universal btn-universal-sim v-btn v-theme--light v-btn--density-default rounded-md v-btn--size-default v-btn--variant-flat"
                        :class="{ 'v-btn--size-large': !isMobile }"
                    >
                        Shop Now
                    </div>
                </div>
            </NuxtLink>
        </v-carousel-item>

        <!-- Apparel -->
        <v-carousel-item>
            <div
                class="d-block video-panel video-panel-apparel position-relative fill-width fill-height"
            >
                <video
                    class="object-fit-cover fill-width fill-height"
                    playsinline
                    muted
                    loop
                    autoplay
                >
                    <source
                        :src="`${cdnBasePathMain}/static/common/videos/hero/apparel-banner-1.mp4`"
                        type="video/mp4"
                    >
                    <track kind="captions">
                </video>
                <div
                    class="video-panel-content position-absolute pa-6 rounded-lg text-center d-flex flex-column align-center justify-center"
                >
                    <div
                        class="mb-8"
                    >
                        <v-img
                            class="img-logo"
                            :src="`${cdnBasePathMain}/static/common/images/carousel-home/stringking-apparel-header-logo.svg`"
                            :width="isMobile ? '70vw' : '30rem'"
                            height="auto"
                            alt="StringKing Baseball"
                        ></v-img>
                    </div>
                    <h5
                        class="text-md-h4 text-h5 font-weight-bold px-2 mb-6 no-invert text-outline"
                    >
                        #1 IN CUSTOM FIT
                    </h5>
                    <div
                        class="d-flex flex-wrap gap-6 justify-center align-center"
                    >
                        <v-btn
                            color="secondary"
                            :size="isMobile ? 'default' : 'large'"
                            to="/search/mens-apparel"
                        >
                            Men's Apparel
                        </v-btn>
                        <v-btn
                            color="secondary"
                            :size="isMobile ? 'default' : 'large'"
                            to="/search/womens-apparel"
                        >
                            Women's Apparel
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-carousel-item>

    </v-carousel>
</template>

<script lang="ts" setup>
    import SliderButton from '~/components/carousel/SliderButton/SliderButton.vue';
    import { useCdnResource, useIsMobile } from '~/composables';

    defineProps({
        cssHeightValue: {
            type: String as PropType<string>,
            default: '100vh'
        }
    });

    const { isMobile } = useIsMobile();
    const { cdnBasePathMain } = useCdnResource();

    const model = ref<number>(0);

    const paused = ref<boolean>(false);

    const interval = ref<number>(10000);

    async function togglePause(val: boolean): Promise<void> {
        await nextTick();
        paused.value = val;
    }
</script>

<style lang="scss">
.hero-carousel {
    .image-panel,
    .video-panel {
        &-content {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: fit-content;
            height: fit-content;

            h4, h5 {
                filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));

                &.no-invert {
                    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
                    color: #fff;
                }

                &.no-drop-shadow {
                    filter: none;
                }
            }

            .img-logo {
                filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.2));
            }
        }
    }

    .slide-move-btn {
        opacity: 0.1;
        transition: opacity 0.4s ease;
    }
    &:hover {
        .slide-move-btn {
            opacity: 0.4;
            transition: opacity 0.4s ease;
        }
    }

    .image-panel-content {
        h4 {
            filter: invert(1) drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.86));
            margin: auto;
        }

        .img-logo {
            filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.8));
        }
    }

    .video-panel-mark-3v {
        background: linear-gradient(0deg, rgb(214, 214, 216) 0%, rgb(220, 220, 222) 100%);
    }

    .video-panel-apparel {
        background: linear-gradient(170deg, rgba(145,157,170,1) 0%, rgba(53,64,64,1) 46%, rgba(118,139,159,1) 52%, rgba(159,176,190,1) 84%, rgba(145,157,170,1) 100%);
    }

    .video-panel-tech-trad {
        background: linear-gradient(180deg, rgba(215,211,206,1) 0%, rgba(206,202,196,1) 42%, rgba(51,60,60,1) 58%, rgba(85,85,71,1) 88%, rgba(117,123,60,1) 100%);
    }

    .video-panel-type-5 {
        background: linear-gradient(180deg, rgba(55,112,127,1) 0%, rgba(160,183,196,1) 30%);
    }

    .video-panel-metal-3-pro {
        background: linear-gradient(180deg, rgba(196,195,200,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 80%, rgba(221,207,185,1) 100%);
    }

    .video-panel-bbcor {
        background: linear-gradient(180deg, rgba(121,147,187,1) 0%, rgba(122,140,179,1) 50%, rgba(126,121,157,1) 69%, rgba(206,204,195,1) 90%);
    }

    .v-window__left,
    .v-window__right {
        top: calc(50% - 49px);
    }

    .text-outline {
        -webkit-text-stroke: thin rgba(0, 0, 0, 0.1);
    }
}

// Override ContentBlocks
.layout-desktop {
    .hero-carousel {
        h4.content-block-text-title {
            font-size: 3rem !important;
            font-weight: 400;
            line-height: 3.125rem;
            letter-spacing: normal !important;
        }
    }
}
.layout-mobile {
    .hero-carousel {
        .v-window__controls {
            display: none !important;
        }
    }

    .video-panel-mens-composite-2-pro-faceoff {
        video {
            object-position: 12% 0;
        }

        .video-panel-content {
            width: 100%;
            max-width: 100%;
            top: 1rem;
            right: 0;

            h4, h5 {
                white-space: normal;
                filter: drop-shadow(
                    0 1px 1px rgba(255, 255, 255, 0.8)
                )
                drop-shadow(
                    0 -1px 1px rgba(255, 255, 255, 0.8)
                );
            }
        }
    }
}
</style>
