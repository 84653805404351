export const FeaturedProductsList = [
    {
        key: 'products',
        title: 'Featured Men’s Lacrosse Products',
        items: [
            {
                title: 'Mark 2v Midfield Head',
                path: '/mens-lacrosse/heads/mark-2v/',
                image: 'static/common/images/featured-home/StringKing-Mark-2V-Mens-Midfield-Lacrosse-Head-Strung-Category-Image-1440x960.jpg',
                imageUrl: null,
                imgClass: null,
                altText: `StringKing Mark 2V Men's Midfield Lacrosse Head Strung Black White Category Image`
            },
            {
                title: 'Metal 3 Pro Attack',
                path: '/mens-lacrosse/shafts/metal-3-pro-attack',
                image: 'static/common/images/featured-home/metal-3-pro-features.webp',
                imageUrl: null,
                imgClass: null,
                altText: 'StringKing Metal 3 Pro Attack - Built to perform'
            },
            {
                title: 'Complete 2 Jr.',
                path: '/mens-lacrosse/sticks/complete-2-jr/',
                image: 'static/common/images/featured-home/Complete-2-Jr-Homepage-Category-1.jpg',
                imageUrl: null,
                imgClass: null,
                altText: 'StringKing Complete 2 Jr Homepage Image White Black Lacrosse Sticks'
            }
        ]
    },
    {
        key: 'w_products',
        title: 'Featured Women’s Lacrosse Products',
        items: [
            {
                title: 'Complete 2 Pro',
                path: '/womens-lacrosse/sticks/womens-complete-2-pro-offense/',
                image: '/static/common/images/featured-home/womans-lacrosse/1-womens-complete-2-pro-offense-tech-trad_sm.webp',
                imageUrl: null,
                imgClass: null,
                altText: `StringKing Women's Complete 2 Pro Lacrosse Stick Featured Product`
            },
            {
                title: 'Complete',
                path: '/womens-lacrosse/sticks/womens-complete/',
                image: '/static/common/images/featured-home/womans-lacrosse/2--womens-complete-tech-trad_sm.webp',
                imageUrl: null,
                imgClass: null,
                altText: `StringKing Women's Complete Lacrosse Stick Featured Product`
            },
            {
                title: 'Starter',
                path: '/womens-lacrosse/sticks/girls-starter/',
                image: '/static/common/images/featured-home/womans-lacrosse/3-womens-starter-type-4_sm.webp',
                imageUrl: null,
                imgClass: null,
                altText: `StringKing Women's Starter Lacrosse Stick Featured Product`
            }
        ]
    },
    {
        key: 'featured_baseball',
        title: 'Featured Baseball Bats',
        items: [
            {
                title: 'METAL 2 PRO USSSA BATS',
                path: '/baseball/usssa-baseball-bats/metal-2-pro-usssa/',
                image: 'static/common/images/featured-home/Metal-2-Pro-USSSA-Homepage-Featured.webp',
                imageUrl: null,
                imgClass: null,
                altText: 'Baseball Metal 2 Pro USSSA Youth Big Barrel Senior League Bat'
            },
            {
                title: 'METAL 2 PRO BBCOR BATS',
                path: '/baseball/bbcor-baseball-bats/metal-2-pro-bbcor/',
                image: 'static/common/images/featured-home/Metal-2-Pro-BBCOR-Homepage-Featured.webp',
                imageUrl: null,
                imgClass: null,
                altText: 'StringKing Metal 2 Pro BBCOR Baseball Bat Featured Product'
            },
            {
                title: 'METAL PRO USA BASEBALL BATS',
                path: '/baseball/usabat-baseball-bats/metal-pro-usabat/',
                image: 'static/common/images/featured-home/StringKing-Baseball-Metal-Pro-USABat-29in-Homepage-Feature-Tag-768x512.jpg',
                imageUrl: null,
                imgClass: null,
                altText: 'StringKing Baseball Metal Pro USABat 29in Homepage Feature'
            }
        ]
    },
    {
        key: 'featured_softball',
        title: 'Featured Softball Bats',
        items: [
            {
                title: 'METAL PRO FASTPITCH -10 BATS',
                path: '/softball/fastpitch-softball-bats/metal-pro-fastpitch',
                image: 'static/common/images/featured-home/Baseball-HomepageArtboard-10@1.6x.webp',
                imageUrl: null,
                imgClass: null,
                altText: 'Metal Pro Fastpitch Drop 10 Softball Bat'
            },
            {
                title: 'Metal Pro Fastpitch -11 Bats',
                path: '/softball/fastpitch-softball-bats/metal-pro-fastpitch',
                image: 'static/common/images/featured-home/Baseball-HomepageArtboard-11@1.6x.webp',
                imageUrl: null,
                imgClass: null,
                altText: 'Metal Pro Fastpitch Drop 11 Softball Bat'
            },
            {
                title: 'METAL PRO SLOWPITCH BATS',
                path: '/softball/slowpitch-softball-bats/metal-pro-usa-slowpitch',
                image: 'static/common/images/featured-home/Baseball-HomepageArtboard-3@1.6x.webp',
                imageUrl: null,
                imgClass: null,
                altText: 'Metal Pro Slowpitch Softball Bat'
            }
        ]
    },
    {
        key: 'featured_apparel',
        title: 'Featured Performance Apparel',
        items: [
            {
                title: 'Men\'s Flextech Short Sleeve Crewneck T-Shirt',
                path: '/mens-apparel/t-shirts/mens-flextech-short-sleeve-crewneck-t-shirt/',
                image: 'static/common/images/featured-home/featured-performance-apparel-01.webp',
                imageUrl: null,
                imgClass: 'sku-img-filter-1',
                altText: 'StringKing FlexTech Short Sleeve Crewneck T-Shirt, Black, Male, Sharp'
            },
            {
                title: 'Men\'s Flexstyle Short Sleeve Polo',
                path: '/mens-apparel/polos/mens-flexstyle-short-sleeve-polo',
                image: 'static/common/images/featured-home/featured-performance-apparel-02.webp',
                imageUrl: null,
                imgClass: 'sku-img-filter-1',
                altText: 'StringKing FlexStyle Short Sleeve Polo, Heather Gray, Male, Sharp'
            },
            {
                title: 'Men\'s Pima Cotton Crewneck Sweatshirt',
                path: '/mens-apparel/sweatshirts/mens-pima-cotton-crewneck-sweatshirt',
                image: 'static/common/images/featured-home/featured-performance-apparel-03.webp',
                imageUrl: null,
                imgClass: 'sku-img-filter-1',
                altText: 'StringKing Pima Cotton Crewneck Sweatshirt, Navy, Male, Relaxed'
            }
        ]
    }
];

export const FeaturedVideosList = [
    {
        title: 'Matt Gibson Wall Ball Lacrosse',
        youtubeId: 'rMKupygKYU0',
        thumbnail: 'static/common/images/featured-home/StringKing-Homepage-Video-Lacrosse-480x320-1920x1280.jpg'
    },
    {
        title: 'Metal 2 Pro BBCOR Baseball Bat',
        youtubeId: '_Kqw4QhhLzE',
        thumbnail: 'static/common/images/featured-home/StringKing-Homepage-Video-Baseball-480x320-1920x1280.jpg'
    },
    {
        title: '24-hour Custom-Fit Apparel',
        youtubeId: 'vYIs0JaI_yg',
        thumbnail: 'static/common/images/featured-home/StringKing-Homepage-Video-Apparel-480x320-1920x1280.jpg'
    }
];
